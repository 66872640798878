import React from 'react';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';
import Icon from 'react-icons-kit';
import { arrowRight2 } from 'react-icons-kit/icomoon/arrowRight2';
import { Link, Text } from './learnMoreButton.style';

const LearnMoreButton = ({ textID, link, ...props }) => {
  const intl = useIntl();

  return (
    <Link href={link} {...props}>
      <Text>{intl.formatMessage({ id: textID })}</Text>
      <Icon className="linkArrow" icon={arrowRight2} />
    </Link>
  );
};

LearnMoreButton.propTypes = {
  textID: PropTypes.string,
  link: PropTypes.string,
};

LearnMoreButton.defaultProps = {};

export default LearnMoreButton;
