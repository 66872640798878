import React, { Fragment, useState } from 'react';
import { ThemeProvider } from 'styled-components';
import Sticky from 'react-stickynode';
import { DrawerProvider } from 'common/src/contexts/DrawerContext';
import { saasModernTheme } from 'common/src/theme/saasModern';
import { ResetCSS } from 'common/src/assets/css/style';
import {
  GlobalStyle,
  ContentWrapper,
  StyledCookieBanner,
} from '../containers/SaasModern/sassModern.style';
import BannerSection from '../containers/SaasModern/LandingBanner';
import Navbar from '../containers/SaasModern/Navbar';
import ClarityScore from '../containers/SaasModern/ClarityScore';
import Aoi from '../containers/SaasModern/AreasOfInterest';
import DataDrivenDesign from '../containers/SaasModern/DataDrivenDesign';
import TrialSection from '../containers/SaasModern/Trial';
import Benefits from '../containers/SaasModern/Benefits';
import Footer from '../containers/SaasModern/Footer';
import UpdateScreen from '../containers/SaasModern/UpdateScreen';
import FeatureSection from '../containers/SaasModern/FeatureSection';
import ProblemSection from '../containers/SaasModern/ProblemSection';
import Steps from '../containers/SaasModern/Steps';
import Ai from '../containers/SaasModern/AI';
import Seo from '../components/seo';
import { useIntl } from 'gatsby-plugin-react-intl';
import { HideOn } from 'react-hide-on-scroll';
import languages from '../intl/languages';
import ClueifyLogo from '../../static/assets/icon-new.png';
import SupportSection from '../containers/SaasModern/Support';

const HomePage = ({ location }) => {
  const [state, setState] = useState({
    navbarFixed: false,
  });

  const navbarFixed = state.navbarFixed;
  const intl = useIntl();

  return (
    <ThemeProvider theme={saasModernTheme}>
      <Fragment>
        <Seo
          lang={intl.locale}
          title="Clueify - Data Driven Design"
          description={intl.formatMessage({ id: 'landing.seoDescription' })}
          locales={languages}
          pathName={location.pathname}
          hideTitleTemplate={true}
          twitterImage={ClueifyLogo}
        />
        <ResetCSS />
        <GlobalStyle />
        <ContentWrapper>
          <Sticky
            top={0}
            innerZ={9999}
            activeClass="sticky-nav-active"
            onStateChange={status =>
              setState({
                navbarFixed: status.status === Sticky.STATUS_FIXED,
              })
            }
          >
            <DrawerProvider>
              <Navbar fixed={navbarFixed} />
            </DrawerProvider>
          </Sticky>
          <BannerSection />
          <ProblemSection />
          <Steps />
          <FeatureSection />
          <UpdateScreen />
          <Aoi />
          <ClarityScore />
          <DataDrivenDesign />
          <Benefits />
          <Ai />
          <TrialSection />
          <SupportSection />
          <Footer />
          <HideOn showOnPageInit={false} inverse divID="cookie_banner_trigger">
            <StyledCookieBanner
              onAccept={() => {}}
              onAcceptPreferences={() => {}}
              onAcceptStatistics={() => {}}
              onAcceptMarketing={() => {}}
              showPreferencesOption={false}
              showDeclineButton={true}
              necessaryOptionText={intl.formatMessage({
                id: 'landing.cookie_option1',
              })}
              statisticsOptionText={intl.formatMessage({
                id: 'landing.cookie_option2',
              })}
              marketingOptionText={intl.formatMessage({
                id: 'landing.cookie_option3',
              })}
              privacyPolicyLinkText={intl.formatMessage({
                id: 'landing.cookie_banner3',
              })}
              policyLink="/privacy-policy"
              acceptButtonText={intl.formatMessage({
                id: 'landing.cookie_accept_Button',
              })}
              declineButtonText={intl.formatMessage({
                id: 'landing.cookie_decline_Button',
              })}
            />
          </HideOn>
        </ContentWrapper>
      </Fragment>
    </ThemeProvider>
  );
};

export default HomePage;
