import React from 'react';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import { StaticImage } from 'gatsby-plugin-image';
import SectionWrapper, { ContentWrapper, ContainerWrapper } from './ai.style';
import PropTypes from 'prop-types';
import { withPrefix } from 'gatsby';
import { useIntl } from 'gatsby-plugin-react-intl';

const AI = ({ header, text, footNote }) => {
  const intl = useIntl();

  return (
    <SectionWrapper className="aiSectionWrapper" id="ai_section">
      <ContainerWrapper className="aiContainerWrapper">
        <ContentWrapper>
          <div className="content">
            <Heading
              content={intl.formatMessage({ id: 'landing.explanation_title' })}
              {...header}
            />
            <Text
              content={intl.formatMessage({ id: 'landing.explanation_text' })}
              {...text}
            />
            <Text
              content={intl.formatMessage({ id: 'landing.explanation_text2' })}
              {...text}
            />
            <p style={footNote}>
              {intl.formatMessage({ id: 'landing.explanation_text3' })}{' '}
              <a
                href={withPrefix(
                  intl.formatMessage({
                    id: 'landing.footer_accuracy_study_link',
                  })
                )}
                target="_blank"
                rel="noopener noreferrer"
                style={{
                  textDecoration: 'underline',
                  color: '#081d34',
                }}
              >
                {intl.formatMessage({ id: 'landing.explanation_text4' })}
              </a>{' '}
              {intl.formatMessage({ id: 'landing.explanation_text5' })}
            </p>
          </div>
          <div className="image">
            <StaticImage
              src="../../../images/ai_training_progress.svg"
              alt="Clueify AI"
            />
          </div>
        </ContentWrapper>
      </ContainerWrapper>
    </SectionWrapper>
  );
};

AI.propTypes = {
  descriptionHeader: PropTypes.object,
  header: PropTypes.object,
  text: PropTypes.object,
};

AI.defaultProps = {
  descriptionHeader: {
    textTransform: 'uppercase !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['15px !important'],
    fontWeight: 'bold !important',
    color: '#081d34 !important',
    letterSpacing: 'normal !important',
  },
  header: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
  },
  text: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
  },
  footNote: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34',
    fontWeight: 300,
    fontSize: '10px',
    marginBottom: '15px',
  },
};

export default AI;
