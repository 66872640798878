import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import StepsSectionWrapper from './steps.style';
import { useIntl } from 'gatsby-plugin-react-intl';
import Step from './step';
import Plugins from '../Plugins';
import LearnMoreButton from '../../../components/LearnMoreButton';
import CTAButton from '../../../components/CTAButton';
import { StaticImage } from 'gatsby-plugin-image';
import UploadImage from '../../../images/steps/clueify_upload_design.mp4';

const Steps = ({ row, sectionHeader, sectionTitle, subTitle, ctaWrapper }) => {
  const intl = useIntl();

  const stepsData = [
    {
      key: 'step1',
      additionalComponent: <Plugins />,
      imageComponent: (
        <video
          autoPlay={true}
          loop={true}
          playsInline={true}
          muted={true}
          style={{
            boxShadow: '8px 8px 0 0 #000',
            transform: 'none',
            padding: '4px 4px 0px 4px',
          }}
        >
          <source src={UploadImage} type="video/mp4" />
        </video>
      ),
    },
    {
      key: 'step2',
      additionalComponent: (
        <LearnMoreButton
          textID="general.allFeatures"
          link="#attention_maps_section"
        />
      ),
      imageComponent: (
        <StaticImage
          src="../../../images/steps/Clueify_Heatmap.png"
          alt="clueify analysis results - heatmap"
          style={{
            boxShadow: '-9px 8px 0 0 #000',
            transform: 'translate(8px, -8px)',
            borderRadius: '1rem',
          }}
        />
      ),
    },
    {
      key: 'step3',
      imageComponent: (
        <StaticImage
          src="../../../images/steps/Design_Optimization.svg"
          alt="clueify A/B-Testing"
          style={{
            boxShadow: '8px 8px 0 0 #000',
            transform: 'none',
            borderRadius: '1rem',
          }}
        />
      ),
    },
    {
      key: 'step4',
      additionalComponent: (
        <div style={{ marginTop: '1.5rem' }}>
          <CTAButton className="mainLeadInput" fontSize="1rem" />
        </div>
      ),
      imageComponent: (
        <StaticImage
          src="../../../images/steps/Design_Presentation.svg"
          alt="design presentation"
          style={{
            boxShadow: '-8px 8px 0 0 #000',
            transform: 'translate(8px, -8px)',
            borderRadius: '1rem',
          }}
        />
      ),
    },
  ];

  return (
    <StepsSectionWrapper>
      <Container>
        <Box {...sectionHeader}>
          <Heading
            content={intl.formatMessage({ id: 'campaign.steps.title' })}
            {...sectionTitle}
          />
          <Heading
            content={intl.formatMessage({ id: 'campaign.steps.description' })}
            {...subTitle}
          />
        </Box>
        <Box display="flex" {...ctaWrapper}>
          <CTAButton className="mainLeadInput" fontSize="1rem" />
        </Box>
        <Box className="row" {...row}>
          {stepsData.map((step, index) => (
            <Step
              key={step.key}
              textAreaFirst={index % 2 === 0}
              title={intl.formatMessage({
                id: `campaign.steps.${step.key}.title`,
              })}
              imageComponent={step.imageComponent}
              description={intl.formatMessage({
                id: `campaign.steps.${step.key}.description`,
              })}
              hint={intl.formatMessage({
                id: `campaign.steps.${step.key}.hint`,
              })}
              additionalInfo={
                index === 0 || index === 1
                  ? intl.formatMessage({
                      id: `campaign.steps.${step.key}.additionalInfo`,
                    })
                  : ''
              }
              additionalComponent={step.additionalComponent}
            />
          ))}
        </Box>
      </Container>
    </StepsSectionWrapper>
  );
};

Steps.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  sectionTitle: PropTypes.object,
  subTitle: PropTypes.object,
  ctaWrapper: PropTypes.object,
};

Steps.defaultProps = {
  sectionHeader: {
    style: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  sectionTitle: {
    as: 'h2',
    textAlign: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['1.4rem', '1.9rem', '2.2rem'],
    lineHeight: '1.4',
    fontWeight: 'bold',
    color: '#081d34',
    mb: ['1rem', '1rem', '2rem', '2rem'],
  },
  subTitle: {
    as: 'h4',
    textAlign: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['1rem', '1.2rem'],
    fontWeight: 300,
    lineHeight: '1.4',
    color: '#081d34',
    mb: ['1rem', '2rem'],
  },
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'center',
  },
  ctaWrapper: {
    style: {
      justifyContent: 'center',
      marginBottom: '6rem',
    },
  },
};

export default Steps;
