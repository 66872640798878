import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import fancySectionEdgeImage from '../../../images/fancy_section_edge.svg';

const SectionWrapper = styled.div`
  padding: 65px 0 95px;
  overflow: hidden;
  background-color: #f5f5f5;
  &:before {
    content: '';
    transform: rotate(180deg);
    position: absolute;
    background-color: #f5f5f5;
    height: 17.5px;
    mask-image: url(${fancySectionEdgeImage});
    mask-repeat: repeat no-repeat;
    width: 100vw;
    z-index: 2;
    margin-top: -82px;
  }
  &:after {
    content: '';
    transform: translateX(-50%);
    position: absolute;
    background-color: #f5f5f5;
    height: 18px;
    left: 50%;
    mask-image: url(${fancySectionEdgeImage});
    mask-repeat: repeat no-repeat;
    width: 100vw;
    z-index: 2;
    margin-top: 94px;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  .image {
    width: 50%;
    @media only screen and (max-width: 991px) {
      width: 100%;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .content {
    width: 50%;
    padding-right: 3.5rem;
    @media only screen and (max-width: 991px) {
      width: 100%;
      padding-right: 0rem;
      margin-bottom: 2.5rem;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding-right: 0rem;
      margin-bottom: 2.5rem;
    }
    h2 {
      color: ${themeGet('colors.headingColor', '#081d34')};
      font-size: 36px;
      line-height: 44px;
      font-weight: 700;
      letter-spacing: -1px;
      margin-bottom: 27px;
      max-width: 400px;
      @media only screen and (max-width: 1366px) {
        font-size: 30px;
        line-height: 42px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 991px) {
        font-size: 26px;
        line-height: 38px;
        text-align: center;
        max-width: none;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        text-align: center;
        max-width: none;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: #6e7379;
      margin-bottom: 1rem;
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
      @media only screen and (max-width: 768px) {
        text-align: center;
      }
    }
    h5 {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 12px;
      letter-spacing: 1.5px;
      color: ${themeGet('colors.primary', '#081d34')};
      text-transform: uppercase;
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
      @media only screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }
  .reusecore__button {
    border-radius: 5px;
    &:hover {
      background-color: ${themeGet('colors.primaryHover', '#3C74FF')};
    }
  }
`;

export default SectionWrapper;
