import React from 'react';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import SectionWrapper, {
  ContentWrapper,
  DescriptionWrapper,
} from './step.style';
import PropTypes from 'prop-types';

const Step = ({
  textAreaFirst,
  header,
  hintHeader,
  textStyle,
  title,
  description,
  hint,
  additionalInfo,
  additionalComponent,
  imageComponent,
  imagePadding,
  ...rest
}) => {
  const getImage = () => {
    return (
      <div
        className="image"
        data-sal={textAreaFirst ? 'slide-left' : 'slide-right'}
      >
        {imageComponent}
      </div>
    );
  };

  const getLeftImageSection = () => {
    if (!textAreaFirst) {
      return getImage();
    }
  };

  const getRightImageSection = () => {
    if (textAreaFirst) {
      return getImage();
    }
  };

  return (
    <SectionWrapper {...rest}>
      <ContentWrapper className={textAreaFirst ? 'textFirst' : 'textLast'}>
        {getLeftImageSection()}
        <DescriptionWrapper
          className="content"
          data-sal={textAreaFirst ? 'slide-right' : 'slide-left'}
        >
          <Heading
            as="h5"
            content={hint}
            style={{
              textTransform: 'uppercase',
            }}
            {...hintHeader}
          />
          <Heading content={title} {...header} />
          <div className="stepDescriptionContainer">
            <Text content={description} {...textStyle} />
          </div>
          {additionalInfo && (
            <div className="stepHintContainer">
              <Text content={additionalInfo} {...textStyle} />
            </div>
          )}
          {additionalComponent}
        </DescriptionWrapper>
        {getRightImageSection()}
      </ContentWrapper>
    </SectionWrapper>
  );
};

Step.propTypes = {
  header: PropTypes.object,
  textStyle: PropTypes.object,
  title: PropTypes.string,
  description: PropTypes.string,
  hint: PropTypes.string,
  hintHeader: PropTypes.object,
  textAreaFirst: PropTypes.bool,
  additionalInfo: PropTypes.string,
  additionalComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object,
  ]),
  imageComponent: PropTypes.oneOfType([
    PropTypes.elementType,
    PropTypes.object,
  ]),
  imagePadding: PropTypes.string,
};

Step.defaultProps = {
  header: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['1.2rem', '1.6rem'],
    lineHeight: '1.4',
    fontWeight: 'bold',
    color: '#081d34 !important',
    as: 'h3',
    mb: ['1.4rem', '1.8rem'],
  },
  textStyle: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
    fontSize: '1rem',
    lineHeight: '1.4',
  },
  hintHeader: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: '0.8rem',
    fontWeight: 'bold !important',
    color: '#081d34 !important',
    letterSpacing: 'normal !important',
  },
  textAreaFirst: true,
  imagePadding: 'none',
};

export default Step;
