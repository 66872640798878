import ClientOne from '../../assets/image/saasModern/plugin_sketch_logo.png';
import ClientTwo from '../../assets/image/saasModern/plugin_figma_logo.png';
import ClientThree from '../../assets/image/saasModern/plugin_xd_logo.png';
import ClientFour from '../../assets/image/saasModern/plugin_chrome_logo.png';

import ClientOneIcon from '../../assets/image/saasModern/plugin_sketch_logo_icon.png';
import ClientTwoIcon from '../../assets/image/saasModern/plugin_figma_logo_icon.png';
import ClientThreeIcon from '../../assets/image/saasModern/plugin_xd_logo_icon.png';
import ClientFourIcon from '../../assets/image/saasModern/plugin_chrome_logo_icon.png';

import { fire } from 'react-icons-kit/icomoon/fire';
import { lowVision } from 'react-icons-kit/fa/lowVision';
import { ic_share } from 'react-icons-kit/md/ic_share';

import FeatureIcon1 from '../../assets/image/saasModern/ux_designer_icon.svg';
import FeatureIcon2 from '../../assets/image/saasModern/product_manager.svg';
import FeatureIcon3 from '../../assets/image/saasModern/marketing_manager_icon.svg';

import BenefitIcon1 from '../../assets/image/saasModern/benefit1.svg';
import BenefitIcon2 from '../../assets/image/saasModern/benefit2.svg';
import BenefitIcon3 from '../../assets/image/saasModern/benefit3.svg';
import BenefitIcon4 from '../../assets/image/saasModern/benefit4.svg';
import BenefitIcon5 from '../../assets/image/saasModern/benefit5.svg';
import BenefitIcon6 from '../../assets/image/saasModern/benefit6.svg';

export const client = [
  {
    id: 1,
    image: ClientOne,
    icon: ClientOneIcon,
    title: 'Sketch',
  },
  {
    id: 2,
    image: ClientTwo,
    icon: ClientTwoIcon,
    title: 'Figma',
  },
  {
    id: 3,
    image: ClientThree,
    icon: ClientThreeIcon,
    title: 'Adobe XD',
  },
  {
    id: 4,
    image: ClientFour,
    icon: ClientFourIcon,
    title: 'Chrome',
  },
];

export const MENU_ITEMS = [
  {
    label: 'Home',
    path: '#banner_section',
    offset: '0',
  },
  {
    label: 'Feature',
    path: '#feature_section',
    offset: '0',
  },
  {
    label: 'Pricing',
    path: '#pricing_section',
    offset: '0',
  },
  {
    label: 'Testimonial',
    path: '#testimonial_section',
    offset: '0',
  },
  {
    label: 'FAQ',
    path: '#faq_section',
    offset: '0',
  },
];

export const SCREENSHOTS = [
  {
    icon: fire,
    title: 'HEATMAP',
    description: 'landing.heatmap',
  },
  {
    icon: ic_share,
    title: 'GAZEPLOT',
    description: 'landing.gazeplot',
  },
  {
    icon: lowVision,
    title: 'FOCUS MAP',
    description: 'landing.opacity',
  },
];

export const FEATURES = [
  {
    id: 1,
    icon: FeatureIcon1,
    title: 'landing.ux_designer_title',
    description: 'landing.ux_designer_text',
  },
  {
    id: 2,
    icon: FeatureIcon2,
    title: 'landing.product_manager_title',
    description: 'landing.product_manager_text',
  },
  {
    id: 3,
    icon: FeatureIcon3,
    title: 'landing.digital_marketing_manager_title',
    description: 'landing.digital_marketing_manager_text',
  },
];

export const BENEFITS = [
  {
    id: 1,
    icon: BenefitIcon1,
    title: 'landing.advantages1',
  },
  {
    id: 2,
    icon: BenefitIcon2,
    title: 'landing.advantages2',
  },
  {
    id: 3,
    icon: BenefitIcon3,
    title: 'landing.advantages3',
  },
  {
    id: 4,
    icon: BenefitIcon4,
    title: 'landing.advantages4',
  },
  {
    id: 5,
    icon: BenefitIcon5,
    title: 'landing.advantages5',
  },
  {
    id: 6,
    icon: BenefitIcon6,
    title: 'landing.advantages6',
  },
];
