import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Image from 'reusecore/src/elements/Image';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import { BENEFITS } from 'common/src/data/SaasModern';
import { FeatureSection } from './benefits.style';
import { useIntl } from 'gatsby-plugin-react-intl';

const Benefits = ({
  row,
  col,
  title,
  description,
  btnStyle,
  cardArea,
  featureTitleStyle,
  featureDescriptionStyle,
  header,
}) => {
  const intl = useIntl();
  return (
    <Container noGutter mobileGutter>
      <Box className="row" {...row} {...cardArea}>
        <Heading
          content={intl.formatMessage({ id: 'landing.title5' })}
          {...header}
        />
        <FeatureSection>
          <div style={{ padding: '0 3rem 0 3rem' }}>
            {BENEFITS.slice(0, 3).map((item, index) => (
              <div key={`feature-${index}`} className="featureWrapper">
                <Image src={item.icon} alt={item.title} />
                <Box className="contextPortion">
                  <Heading
                    as="h3"
                    content={intl.formatMessage({ id: item.title })}
                    {...featureTitleStyle}
                  />
                </Box>
              </div>
            ))}
          </div>
          <div style={{ padding: '0 3rem 0 3rem' }}>
            {BENEFITS.slice(3, 6).map((item, index) => (
              <div key={`feature-${index}`} className="featureWrapper">
                <Image src={item.icon} alt={item.title} />
                <Box className="contextPortion">
                  <Heading
                    as="h3"
                    content={intl.formatMessage({ id: item.title })}
                    {...featureTitleStyle}
                  />
                </Box>
              </div>
            ))}
          </div>
        </FeatureSection>
      </Box>
    </Container>
  );
};

// Transactions style props
Benefits.propTypes = {
  sectionHeader: PropTypes.object,
  sectionTitle: PropTypes.object,
  row: PropTypes.object,
  col: PropTypes.object,
  featureTitleStyle: PropTypes.object,
  featureDescriptionStyle: PropTypes.object,
};

// Trusted default style
Benefits.defaultProps = {
  // Trusted section row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    ml: '-15px',
    mr: '-15px',
    justifyContent: 'center',
    mt: '120px',
  },
  // Trusted section col default style
  col: {
    padding: '0 3rem 0 3rem !important',
  },

  header: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    textAlign: 'center',
  },

  // Trusted section title default style
  title: {
    content: 'A Trusted Proof of Investment Vehicle',
    fontSize: ['24px', '26px', '30px', '36px', '40px'],
    lineHeight: ['30px', '32px', '40px', '50px', '55px'],
    fontWeight: '700',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '20px',
    maxWidth: ['100%', '100%', '100%', '100%', '415px'],
    textAlign: ['left', 'left'],
  },
  // Trusted section description default style
  description: {
    content:
      'Easily buy, sell or exchange over 30 different cryptocurrencies. Now euro deposits and withdrawn available.',
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '28px',
    mb: ['25px', '25px', '30px', '30px', '45px'],
    textAlign: ['left', 'left'],
    maxWidth: ['100%', '100%', '100%', '100%', '430px'],
  },
  // Button default style
  btnStyle: {
    minWidth: '156px',
    fontSize: '14px',
    fontWeight: '500',
  },
  featureTitleStyle: {
    fontSize: ['18px', '18px', '20px', '20px', '20px'],
    lineHeight: ['1', '1', '1', '1', '1'],
    fontWeight: '500',
    color: '#32325d',
    letterSpacing: '-0.010em',
    mb: '0px',
    textAlign: ['left', 'left'],
  },
  // Trusted section description default style
  featureDescriptionStyle: {
    fontSize: '16px',
    fontWeight: '400',
    color: '#525f7f',
    lineHeight: '27px',
    textAlign: ['left', 'left'],
  },
};

export default Benefits;
