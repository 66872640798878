import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import ProblemSectionWrapper from './problemSection.style';
import { useIntl } from 'gatsby-plugin-react-intl';
import Problem from './problem';

const ProblemSection = ({ row, sectionHeader, sectionTitle }) => {
  const intl = useIntl();

  const problems = [
    {
      key: 'problem1',
    },
    {
      key: 'problem2',
    },
  ];

  const bulletPoints = ['first', 'second', 'third'];

  return (
    <ProblemSectionWrapper>
      <Container>
        <Box {...sectionHeader}>
          <Heading
            content={intl.formatMessage({ id: 'campaign.problem.title' })}
            {...sectionTitle}
          />
        </Box>
        <Box className="row" {...row}>
          {problems.map((problem, index) => (
            <Problem
              id={problem.key}
              key={problem.key}
              textAreaFirst={index % 2 === 0}
              title={intl.formatMessage({
                id: `campaign.problem.${problem.key}.title`,
              })}
              bulletPoints={bulletPoints.map((bulletPoint) =>
                intl.formatMessage({
                  id: `campaign.problem.${problem.key}.bulletPoints.${bulletPoint}`,
                })
              )}
            />
          ))}
        </Box>
      </Container>
    </ProblemSectionWrapper>
  );
};

// FeatureSection style props
ProblemSection.propTypes = {
  sectionHeader: PropTypes.object,
  row: PropTypes.object,
  sectionTitle: PropTypes.object,
};

// ProblemSection default style
ProblemSection.defaultProps = {
  // section header default style
  sectionHeader: {
    mb: '2rem',
    style: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
  },
  // section title default style
  sectionTitle: {
    as: 'h2',
    textAlign: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['1.4rem', '1.9rem', '2.2rem'],
    lineHeight: '1.4',
    fontWeight: 'bold',
    color: '#081d34',
    mb: ['1rem', '1rem', '2rem', '2rem'],
  },
  // feature row default style
  row: {
    flexBox: true,
    flexWrap: 'wrap',
    justifyContent: 'space-between',
  },
};

export default ProblemSection;
