import React from 'react';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './clarityScore.style';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';
import { StaticImage } from 'gatsby-plugin-image';

const ClarityScore = ({ descriptionHeader, header, text, footNote }) => {
  const intl = useIntl();

  return (
    <SectionWrapper id="clarity_score_section">
      <Container>
        <ContentWrapper>
          <div className="content">
            <Heading
              as="h5"
              content="Clarity Score"
              {...descriptionHeader}
              style={{ textTransform: 'none' }}
            />
            <Heading
              content={intl.formatMessage({ id: 'landing.clarity' })}
              {...header}
            />
            <Text
              content={intl.formatMessage({ id: 'landing.clarity_text' })}
              {...text}
            />
            <Text content="*Stanford University 2003" {...footNote} />
          </div>
          <div className="image">
            <StaticImage
              src="../../../images/clarity_score_large.svg"
              alt="Clarity Score"
            />
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

ClarityScore.propTypes = {
  descriptionHeader: PropTypes.object,
  header: PropTypes.object,
  text: PropTypes.object,
};

ClarityScore.defaultProps = {
  descriptionHeader: {
    textTransform: 'uppercase !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['15px !important'],
    fontWeight: 'bold !important',
    color: '#081d34 !important',
    letterSpacing: 'normal !important',
  },
  header: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
  },
  text: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
    marginBottom: '15px',
  },
  footNote: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
    fontSize: '10px !important',
    marginBottom: '15px',
  },
};

export default ClarityScore;
