import styled from 'styled-components';
import Icon from 'react-icons-kit';

const SectionWrapper = styled.div`
  overflow: hidden;
`;

export const CrossIcon = styled(Icon)`
  color: #f44336;
  margin-right: 1rem;
`;

export const BulletPointWrapper = styled.div`
  display: flex;
  flexwrap: no-wrap;
  alignitems: flex-start;
`;

export const DescriptionWrapper = styled.div`
  padding: 1rem;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
`;

export const ContentWrapper = styled.div`
  padding-top: 8px;
  display: flex;
  flex-wrap: ${props =>
    props.className === 'textFirst' ? 'wrap' : 'wrap-reverse'};
  align-items: center;
  margin-bottom: 5rem;
  .image {
    padding: 8px;
    width: 50%;
    display: flex;
    justify-content: center;

    .gatsby-image-wrapper,
    video {
      border-radius: 1rem;
      border: solid 2px #000;
      width: 100%;
      max-width: 507px;
    }
    @media only screen and (max-width: 991px) {
      padding-top: 2rem;
      width: 100%;
    }
  }
  .content {
    width: 50%;
    padding-left: ${props =>
      props.className === 'textFirst' ? '0rem' : '4rem'};
    padding-right: ${props =>
      props.className === 'textFirst' ? '4rem' : '0rem'};
    @media only screen and (max-width: 991px) {
      width: 100%;
      padding-left: 0rem;
      padding-right: 0rem;
      align-items: center;

      h3,
      h5,
      p {
        text-align: center;
      }
    }
  }
`;

export default SectionWrapper;
