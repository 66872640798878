import styled from 'styled-components';
import { themeGet } from '@styled-system/theme-get';

const SectionWrapper = styled.div`
  padding: 95px 0 95px;
  overflow: hidden;
  @media (max-width: 1600px) {
    padding: 95px 0 0px;
  }
  @media only screen and (max-width: 1366px) {
    padding: 60px 0;
  }
  @media only screen and (max-width: 667px) {
    padding: 60px 0 0;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  align-items: center;
  margin-bottom: 5rem;
  .image {
    width: 50%;
    @media only screen and (max-width: 991px) {
      width: 100%;
      margin-bottom: 40px;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      margin-bottom: 40px;
    }
  }
  .content {
    width: 50%;
    padding-left: 3.5rem;
    @media only screen and (max-width: 991px) {
      width: 100%;
      padding-left: 0rem;
    }
    @media only screen and (max-width: 768px) {
      width: 100%;
      padding-left: 0rem;
    }
    h2 {
      color: ${themeGet('colors.headingColor', '#081d34')};
      font-size: 36px;
      line-height: 44px;
      font-weight: 700;
      letter-spacing: -1px;
      margin-bottom: 27px;
      @media only screen and (max-width: 1366px) {
        font-size: 30px;
        line-height: 42px;
        margin-bottom: 20px;
      }
      @media only screen and (max-width: 991px) {
        font-size: 26px;
        line-height: 38px;
        text-align: center;
      }
      @media only screen and (max-width: 768px) {
        max-width: 100%;
        text-align: center;
      }
    }
    p {
      font-size: 16px;
      line-height: 28px;
      color: #6e7379;
      margin-bottom: 36px;
      @media only screen and (max-width: 1366px) {
        margin-bottom: 30px;
      }
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
      @media only screen and (max-width: 768px) {
        text-align: center;
      }
    }
    h5 {
      font-size: 14px;
      font-weight: 700;
      line-height: 24px;
      margin-bottom: 12px;
      letter-spacing: 1.5px;
      color: ${themeGet('colors.primary', '#081d34')};
      text-transform: uppercase;
      @media only screen and (max-width: 991px) {
        text-align: center;
      }
      @media only screen and (max-width: 768px) {
        text-align: center;
      }
    }
  }
  .reusecore__button {
    border-radius: 5px;
    &:hover {
      background-color: ${themeGet('colors.primaryHover', '#3C74FF')};
    }
  }
`;

export default SectionWrapper;
