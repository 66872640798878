import React from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Container from 'common/src/components/UI/Container';
import { useIntl } from 'gatsby-plugin-react-intl';
import { Box as MuiBox, makeStyles, Typography } from '@material-ui/core';
import { StaticImage } from 'gatsby-plugin-image';

const useStyles = makeStyles({
    title: {
        paddingRight: "1.5rem",
        textTransform: "uppercase",
        fontSize: "1rem"
    },
    section: {
        paddingBottom: "2.5rem",
        paddingTop: "5rem"
    }
});

const SupportSection = ({
    sectionWrapper,
    row
}) => {
    const intl = useIntl();
    const classes = useStyles();

    return (
        <Box {...sectionWrapper} className={classes.section}>
            <Container mobileGutter={true} >
                <Box {...row}>
                    <MuiBox display="flex" justifyContent="center">
                        <MuiBox
                            display="flex"
                            alignItems="center"
                            flexWrap="wrap"
                            justifyContent="center"
                        >
                            <Typography
                                className={classes.title}
                                variant="h6"
                            >
                                {intl.formatMessage({ id: 'support.title' })}
                            </Typography>
                            <div className="image">
                                <StaticImage
                                    width="600"
                                    src="../../../images/support/Bayr_StMWi_Logo.png"
                                    alt="Bavarian Ministry of Economics"
                                />
                            </div>
                        </MuiBox>
                    </MuiBox>
                </Box>
            </Container>
        </Box>
    );
};

SupportSection.propTypes = {
    sectionWrapper: PropTypes.object,
    row: PropTypes.object,
};

SupportSection.defaultProps = {
    sectionWrapper: {
        as: 'section'
    },
    row: {
        flexBox: true,
        flexWrap: 'wrap',
        flexDirection: 'column',
        alignItems: 'center',
    }
};

export default SupportSection;
