import styled from 'styled-components';
import {
  color,
  width,
  height,
  display,
  space,
  borders,
  borderColor,
  boxShadow,
  borderRadius,
  alignItems,
  justifyContent,
  position,
  overflow,
  fontSize,
} from 'styled-system';

export const FeatureAnchor = styled.a`
  display: block;
  position: relative;
  top: -140px;
  visibility: hidden;

  @media (max-width: 768px) {
    top: -120px;
  }
`;

export const SectionWrapper = styled.section`
  padding: 80px 0 40px 0;

  @media (max-width: 990px) {
    padding: 40px 0 40px 0;
  }
  @media (max-width: 575px) {
    padding: 30px 0 20px 0;
  }

  @keyframes ScaleInUp {
    from {
      opacity: 0;
      transform: translateY(30px) scale(0.97);
    }
    to {
      opacity: 1;
      transform: translateY(0) scale(1);
    }
  }

  .update-screen-tab {
    border: 0;
    overflow: initial;
    .rc-tabs-ink-bar {
      display: none !important;
    }
    .rc-tabs-bar {
      border: 0;
    }
    .rc-tabs-nav-container {
      margin-bottom: 45px;
      @media (max-width: 767px) {
        margin-bottom: 30px;
      }
      &:after {
        content: '';
        position: absolute;
        width: 100%;
        height: 1px;
        display: block;
        left: 0;
        bottom: 0;
        z-index: -1;
      }
      &:not(.rc-tabs-nav-container-scrolling) {
        .rc-tabs-nav-scroll {
          width: 100%;
          text-align: center;
          .rc-tabs-nav {
            float: none;
            display: block;
            .rc-tabs-tab {
              display: inline-block;
              float: none;
            }
          }
        }
      }
      .rc-tabs-tab {
        font-size: 18px;
        color: #6f7a87;
        font-weight: 400;
        min-width: 230px;
        padding: 0 0 25px 0;
        text-align: center;
        margin-right: 0;
        transition: 0.25s ease-in-out;
        &:hover {
          color: #081d34;
        }
        &:after {
          content: '';
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: 0;
          left: 0;
          display: block;
          @media (max-width: 767px) {
            display: none;
          }
        }
        &:after {
          background: #081d34;
          transform: scaleX(0);
          transform-origin: right center 0;
          transition: transform 0.7s cubic-bezier(0.19, 1, 0.22, 1) 0s;
        }
        &.rc-tabs-tab-active {
          color: #081d34;
          &:after {
            transform: scaleX(1);
            transform-origin: left center 0;
            transition: transform 0.35s cubic-bezier(0.43, 0.49, 0.51, 0.68);
          }
        }
        > div {
          margin-right: 8px;
        }
        i {
          padding-right: 0.6rem;
        }
        @media (max-width: 1199px) {
          font-size: 16px;
          padding: 0 0 20px 0;
          min-width: 170px;
        }
        @media (max-width: 990px) {
          min-width: auto;
          padding: 0 20px 15px 20px;
        }
        @media (max-width: 767px) {
          font-size: 14px;
          svg {
            width: 20px;
          }
        }
      }
    }
    .rc-tabs-content {
      max-width: 915px;
      margin: auto;

      .rc-tabs-tabpane {
        display: flex;
        align-items: center;
        flex-direction: column;
        border-radius: 10px;
        overflow: hidden;
        &.rc-tabs-tabpane-active {
          animation: 0.7s ScaleInUp;
        }
        > img {
          max-width: 100%;
          height: auto;
          display: block;
        }
      }
    }
  }

  .rc-tabs-tab-prev-icon,
  .rc-tabs-tab-next-icon {
    font-size: 20px;
    color: #081d34;
    line-height: 1;
    display: block;
  }

  .icon__wrapper {
    display: none;
    position: relative;
    padding-right: 2rem;
    @media only screen and (max-width: 1300px) {
      img {
        height: 60px;
      }
    }
    @media only screen and (max-width: 667px) {
      img {
        height: 70px;
      }
    }
    i {
      opacity: 0;
      visibility: hidden;
      position: absolute;
      color: var(--color);
      transition: all 0.5s cubic-bezier(0.75, -0.5, 0, 1.75);
      &.plus {
        top: 0;
        left: 0;
        transform: translate(20px, 20px) scale(0.1) rotate(-180deg);
      }
      &.circle {
        top: 0;
        right: 0;
        transform: translate(-20px, 20px) scale(0.1) rotate(-180deg);
      }
      &.star {
        bottom: -5px;
        left: calc(50% - 8px);
        transform: translate(0, -20px) scale(0.1) rotate(-180deg);
      }
    }
  }
`;

export const InfoContainer = styled.div`
  justify-content: center;
  padding: 0.8rem;
  margin-bottom: 1.5rem;
  border-color: rgb(232, 235, 241);
  border-width: 1px;
  border-style: solid;
  width: 100%;
  max-width: 900px;
  border-radius: 8px;
  font-size: 16px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  -webkit-letter-spacing: 0.02em;
  -moz-letter-spacing: 0.02em;
  -ms-letter-spacing: 0.02em;
  letter-spacing: 0.02em;
  line-height: 1.4;
  color: #081d34;
  display: flex;
  align-items: center;
  margin-left: 6px;
  margin-right: 6px;
`;

export const ImageWrapper = styled.div`
  margin: 12px;
  box-shadow: rgb(50 50 93 / 25%) 0px 6px 12px -2px,
    rgb(0 0 0 / 30%) 0px 3px 7px -3px;
`;

export const InfoText = styled.p`
  text-align: center;
  line-height: 1.8rem;
`;

// Icon wrapper style
export const IconWrapper = styled.div`
  ${display}
  ${width}
  ${height}
  ${alignItems}
  ${justifyContent}
  ${position}
  ${color}
  ${space}
  ${borders}
  ${borderColor}
  ${boxShadow}
  ${borderRadius}
  ${overflow}
  ${fontSize}
`;
