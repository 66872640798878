import styled from 'styled-components';

const FeatureSection = styled.section`
  padding-top: 80px;
  padding-left: 3rem;
  padding-right: 3rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  @media (max-width: 780px) {
    padding-left: 0px;
    padding-right: 0px;
  }
  .featureWrapper {
    align-items: center;
    margin-bottom: 45px;
    display: flex;
    @media (max-width: 599px) {
      max-width: 100%;
    }
    img {
      width: 50px;
      height: 50px;
      object-fit: contain;
      padding: 8px 10px;
    }
    .contextPortion {
      margin-left: 15px;
    }
  }
`;
export { FeatureSection };
