import React from 'react';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import SectionWrapper, {
  ContentWrapper,
  CrossIcon,
  BulletPointWrapper,
  DescriptionWrapper,
} from './problem.style';
import PropTypes from 'prop-types';
import { cross } from 'react-icons-kit/icomoon/cross';
import { StaticImage } from 'gatsby-plugin-image';

const Problem = ({
  id,
  textAreaFirst,
  header,
  textStyle,
  title,
  bulletPoints,
}) => {
  const getImage = () => {
    let staticImage;
    switch (id) {
      case 'problem1':
        staticImage = (
          <StaticImage
            src="../../../images/problemSection/design_discussion_paths.svg"
            alt="design discussion"
            style={{ borderRadius: '1rem' }}
          />
        );
        break;
      case 'problem2':
        staticImage = (
          <StaticImage
            src="../../../images/problemSection/user-study-clueify.png"
            alt="user study"
            style={{ borderRadius: '1rem' }}
          />
        );
        break;
      default:
        staticImage = null;
    }

    return <div className="image">{staticImage}</div>;
  };

  const getLeftImageSection = () => {
    if (!textAreaFirst) {
      return getImage();
    }
  };

  const getRightImageSection = () => {
    if (textAreaFirst) {
      return getImage();
    }
  };

  return (
    <SectionWrapper>
      <ContentWrapper className={textAreaFirst ? 'textFirst' : 'textLast'}>
        {getLeftImageSection()}
        <DescriptionWrapper className="content">
          <Heading content={title} {...header} />
          <div className="problemBulletPointsContainer">
            {bulletPoints.map((bulletPointText, index) => (
              <BulletPointWrapper key={index}>
                <CrossIcon icon={cross} size="1rem" />
                <Text content={bulletPointText} {...textStyle} />
              </BulletPointWrapper>
            ))}
          </div>
        </DescriptionWrapper>
        {getRightImageSection()}
      </ContentWrapper>
    </SectionWrapper>
  );
};

Problem.propTypes = {
  id: PropTypes.string,
  header: PropTypes.object,
  textStyle: PropTypes.object,
  img: PropTypes.string,
  imgAlt: PropTypes.string,
  title: PropTypes.string,
  bulletPoints: PropTypes.array,
  textAreaFirst: PropTypes.bool,
};

Problem.defaultProps = {
  header: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['1.2rem', '1.4rem'],
    lineHeight: '1.4',
    fontWeight: 'bold',
    color: '#081d34 !important',
    as: 'h3',
    mb: '1.8rem',
  },
  textStyle: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
    fontSize: '1rem',
    lineHeight: '1.4',
  },
  textAreaFirst: true,
};

export default Problem;
