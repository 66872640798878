import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Box from 'reusecore/src/elements/Box';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import { BannerWrapper, ImageSliderWrapper } from './banner.style';
import CTAButton from '../../../components/CTAButton';
import ReactCompareImage from '../../../components/ReactCompareImage';
import { useIntl } from 'gatsby-plugin-react-intl';
import { makeStyles } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { checkmark } from 'react-icons-kit/icomoon/checkmark';
import Icon from 'react-icons-kit';

const useStyles = makeStyles(theme => ({
  imageSlider: {
    '&> div:nth-child(1)': {
      height: 'auto !important',
    },
  },
  skeleton: {
    transform: 'none',
    borderRadius: '8px',
  },
  actionButton: {
    margin: '0.5rem',
  },
}));

const BannerSection = ({
  column,
  checksArea,
  checkWrapper,
  checkTextStyle,
  checkIconStyle,
  contentArea,
  imageArea,
  greetingStyle,
  greetingStyleTwo,
}) => {
  const intl = useIntl();
  const classes = useStyles();
  const containerRef = useRef(null);
  const [skeletonVisible, setSkeletonVisible] = useState(true);

  const checks = [
    'campaign.banner.checks.first',
    'campaign.banner.checks.second',
    'campaign.banner.checks.third',
  ];

  const handleClickDemoCall = () => {
    if (typeof window !== 'undefined') {
      window.location.href = `https://calendly.com/clueify-adrian/30min`;
    }
  };

  return (
    <BannerWrapper id="banner_section">
      <Container noGutter mobileGutter width="1200px" className="container">
        <Box {...column}>
          <div ref={containerRef} {...contentArea}>
            <Heading
              content={intl.formatMessage(
                { id: 'campaign.banner.title' },
                { linebreak: <br className="headingLineBreak" /> }
              )}
              {...greetingStyle}
            />
            <Heading
              content={intl.formatMessage(
                { id: 'campaign.banner.description' },
                { linebreak: <br className="subHeadingLineBreak" /> }
              )}
              {...greetingStyleTwo}
            />
            <Box className="checksArea" {...checksArea}>
              {checks.map((checkTextId, index) => (
                <Box key={index} {...checkWrapper}>
                  <Icon
                    icon={checkmark}
                    size="0.85rem"
                    style={checkIconStyle}
                  />
                  <Text
                    content={intl.formatMessage({ id: checkTextId })}
                    style={checkTextStyle}
                  />
                </Box>
              ))}
            </Box>
            <Box
              id="cookie_banner_trigger"
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                flexDirection: 'row',
                marginTop: '2.5rem',
                marginBottom: '2rem',
              }}
            >
              <CTAButton
                className={classes.actionButton}
                fontSize="1rem"
                height="3rem"
              />
              <CTAButton
                colors="secondaryWithBg"
                className={classes.actionButton}
                textID="general.bookDemo"
                fontSize="1rem"
                height="3rem"
                onClick={handleClickDemoCall}
              />
            </Box>
          </div>
        </Box>
      </Container>
      <Container noGutter mobileGutter width="1000px">
        <Box
          {...imageArea}
          className="imageArea"
          style={{ height: 'auto', paddingTop: '2rem' }}
        >
          <ImageSliderWrapper
            className={skeletonVisible && classes.imageSlider}
          >
            <ReactCompareImage
              sliderLineWidth={0}
              hover={true}
              leftImageCss={{
                borderRadius: '8px',
              }}
              rightImageCss={{
                borderRadius: '8px',
              }}
              leftImage="https://storage.googleapis.com/images.clueify.com/website/public/showcase_heatMap_small-7892b69b68d70f028ea34a6d5b9ee1a3.jpg"
              rightImage="https://storage.googleapis.com/images.clueify.com/website/public/showcase_original_small-46acba93718832895c12bfd067eb56d7.jpg"
              rightImageAlt="Website design"
              leftImageAlt="Heatmap of website"
              skeleton={
                <Skeleton
                  className={classes.skeleton}
                  ref={el => {
                    setSkeletonVisible(!!el);
                  }}
                >
                  <img
                    alt="placeholder heatmap of website"
                    height={504}
                    width={1000}
                  ></img>
                </Skeleton>
              }
            />
          </ImageSliderWrapper>
        </Box>
      </Container>
    </BannerWrapper>
  );
};

BannerSection.propTypes = {
  column: PropTypes.object,
  checksArea: PropTypes.object,
  checkWrapper: PropTypes.object,
  checkTextStyle: PropTypes.object,
  checkIconStyle: PropTypes.object,
  contentArea: PropTypes.object,
  imageArea: PropTypes.object,
  greetingStyle: PropTypes.object,
  nameStyle: PropTypes.object,
  designationStyle: PropTypes.object,
  aboutStyle: PropTypes.object,
  roleStyle: PropTypes.object,
  roleWrapper: PropTypes.object,
  greetingStyleTwo: PropTypes.object,
  button: PropTypes.object,
};

BannerSection.defaultProps = {
  column: {
    flexBox: true,
    flexWrap: 'wrap',
    alignItems: 'stretch',
    justifyContent: 'center',
    style: {
      paddingLeft: '15px',
      paddingRight: '15px',
    },
  },
  checksArea: {
    style: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: '2rem',
    },
  },
  checkTextStyle: {
    marginBottom: 0,
    marginLeft: '0.5rem',
    fontSize: '0.85rem',
    color: '#424242',
    fontFamily: 'Lato',
  },
  checkIconStyle: {
    color: '#424242',
  },
  checkWrapper: {
    style: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      paddingLeft: '1rem',
      paddingRight: '1rem',
    },
  },
  contentArea: {
    with: '100%',
    style: {
      maxWidth: '1000px',
      marginTop: '1rem',
    },
  },
  imageArea: {
    width: '100%',
    flexBox: true,
    alignItems: 'center',
    position: 'relative',
  },
  greetingStyle: {
    as: 'h1',
    fontSize: ['1.6rem', '2.1rem', '2.4rem'],
    fontWeight: '700',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    textAlign: 'center',
    lineHeight: '1.4',
    mb: ['1rem', '1rem', '2rem', '2rem'],
  },
  greetingStyleTwo: {
    as: 'h1',
    color: '#081d34',
    fontSize: ['1rem', '1.2rem', '1.4rem'],
    fontWeight: '400',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    textAlign: 'center',
    lineHeight: '1.4',
    mb: '8px',
    style: {
      whiteSpace: 'pre-line',
    },
  },
  roleWrapper: {
    flexBox: true,
    mb: '28px',
  },
  roleStyle: {
    as: 'h4',
    fontSize: ['18px', '18px', '18px', '18px', '20px'],
    fontWeight: '500',
    color: '#fff',
    mb: '0',
    ml: '10px',
  },
  aboutStyle: {
    fontSize: '0.8rem',
    fontFamily: 'Lato',
    fontWeight: '400',
    color: '#081d34',
    lineHeight: '1.4',
    mt: '0.5rem',
  },
  button: {
    type: 'button',
    fontSize: '14px',
    fontWeight: '700',
    fontFamily: 'Lato',
    color: '#fff',
    borderRadius: '4px',
    pl: '22px',
    pr: '22px',
    colors: 'primaryWithBg',
    minHeight: '55px',
    pt: '0px',
    pb: '0',
  },
};

export default BannerSection;
