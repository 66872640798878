import styled from 'styled-components';

const ProblemSectionWrapper = styled.section`
  padding: 9rem 0 3rem 0;
  @media (max-width: 990px) {
    padding: 5rem 0 1rem 0;
  }
`;

export default ProblemSectionWrapper;
