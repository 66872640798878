import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'react-icons-kit';
import Tabs, { TabPane } from 'rc-tabs';
import 'rc-tabs/assets/index.css';
import Box from 'reusecore/src/elements/Box';
import Heading from 'reusecore/src/elements/Heading';
import Container from 'common/src/components/UI/Container';
import TiltShape from '../TiltShape';
import TabContent from 'rc-tabs/lib/TabContent';
import ScrollableInkTabBar from 'rc-tabs/lib/ScrollableInkTabBar';
import {
  InfoContainer,
  SectionWrapper,
  ImageWrapper,
  InfoText,
  FeatureAnchor,
} from './updateScreen.style';
import { SCREENSHOTS } from 'common/src/data/SaasModern';
import { StaticImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-react-intl';
const UpdateScreen = ({ secTitleWrapper, secHeading }) => {
  const intl = useIntl();

  const getImage = (id) => {
    switch (id) {
      case 'HEATMAP':
        return (
          <StaticImage
            src="../../../../../common/src/assets/image/exampleWebPage/jpg/Clueify-AppFrame-Heatmap.jpg"
            alt={`screenshot-${id}`}
          />
        );
      case 'GAZEPLOT':
        return (
          <StaticImage
            src="../../../../../common/src/assets/image/exampleWebPage/jpg/Clueify-AppFrame-gazePlot.jpg"
            alt={`screenshot-${id}`}
          />
        );
      case 'FOCUS MAP':
        return (
          <StaticImage
            src="../../../../../common/src/assets/image/exampleWebPage/jpg/Clueify-AppFrame-opacityMap.jpg"
            alt={`screenshot-${id}`}
          />
        );
      default:
        return null;
    }
  };

  return (
    <SectionWrapper>
      <FeatureAnchor id="attention_maps_section" />
      <TiltShape />
      <Container>
        <Box {...secTitleWrapper}>
          <Heading
            {...secHeading}
            content={intl.formatMessage({ id: 'landing.title3' })}
          />
        </Box>
        <Tabs
          renderTabBar={() => <ScrollableInkTabBar />}
          renderTabContent={() => <TabContent animated={false} />}
          className="update-screen-tab"
        >
          {SCREENSHOTS.map((item, index) => (
            <TabPane
              className="features-tab-pane"
              forceRender={true}
              tab={
                <div>
                  <Icon icon={item.icon} size={24} />
                  <span>{item.title}</span>
                </div>
              }
              key={index + 1}
            >
              <InfoContainer style={{ '--color': `#3DABDD` }}>
                <InfoText>
                  {intl.formatMessage({ id: item.description })}
                </InfoText>
              </InfoContainer>
              <ImageWrapper>{getImage(item.title)}</ImageWrapper>
            </TabPane>
          ))}
        </Tabs>
      </Container>
    </SectionWrapper>
  );
};

UpdateScreen.propTypes = {
  secTitleWrapper: PropTypes.object,
  secText: PropTypes.object,
  secHeading: PropTypes.object,
};

UpdateScreen.defaultProps = {
  secTitleWrapper: {
    mb: ['60px', '80px'],
  },
  secText: {
    as: 'span',
    display: 'block',
    textAlign: 'center',
    fontSize: '14px',
    letterSpacing: '0.15em',
    fontWeight: '700',
    color: '#fff',
    mb: '5px',
  },
  secHeading: {
    textAlign: 'center',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['20px', '24px'],
    fontWeight: 'bold',
    color: '#081d34',
    letterSpacing: '-0.025em',
    mb: '0',
  },
};

export default UpdateScreen;
