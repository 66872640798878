import React from 'react';
import Text from 'reusecore/src/elements/Text';
import Heading from 'reusecore/src/elements/Heading';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './aoi.style';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

const AreasOfInterest = ({ descriptionHeader, header, text }) => {
  const intl = useIntl();
  return (
    <SectionWrapper id="areas_of_interest_section">
      <Container>
        <ContentWrapper>
          <div className="image">
            <StaticImage
              src="../../../images/aoi_description.svg"
              alt="Areas of Interest"
              style={{ borderRadius: '1rem' }}
            />
          </div>
          <div className="content">
            <Heading
              as="h5"
              content="Areas of Interest"
              {...descriptionHeader}
              style={{ textTransform: 'none' }}
            />
            <Heading
              content={intl.formatMessage({ id: 'landing.aoi' })}
              {...header}
            />
            <Text
              content={intl.formatMessage({ id: 'landing.aoi_text' })}
              {...text}
            />
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

AreasOfInterest.propTypes = {
  descriptionHeader: PropTypes.object,
  header: PropTypes.object,
  text: PropTypes.object,
};

AreasOfInterest.defaultProps = {
  descriptionHeader: {
    textTransform: 'uppercase !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['15px !important'],
    fontWeight: 'bold !important',
    color: '#081d34 !important',
    letterSpacing: 'normal !important',
  },
  header: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
  },
  text: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
  },
};

export default AreasOfInterest;
