import styled from 'styled-components';

export const BannerWrapper = styled.section`
  padding: 11rem 0 4rem 0;
  background-size: cover;
  background-position: top center;

  .mainLeadInput {
    div:first-of-type {
      input {
        padding: 12px 21px !important;
        border-right: 0px;
      }
    }
    button {
      padding: 12px 21px !important;
    }
  }

  @media (max-width: 1000px) {
    .headingLineBreak {
      display: none;
    }
  }

  @media (max-width: 768px) {
    padding: 7.5rem 0 3rem 0;
    .mainLeadInput {
      flex-direction: column;
      div:first-of-type {
        width: 100%;
        input {
          border-top-right-radius: 6px !important;
          border-bottom-right-radius: 6px !important;
          border-right: 2px solid #081d34 !important;
        }
      }
      button {
        width: 100%;
        margin-top: 10px;
        border-top-left-radius: 6px !important;
        border-bottom-left-radius: 6px !important;
      }
    }

    .subHeadingLineBreak {
      display: none;
    }

    .checksArea {
      display: none !important;
    }
  }

  img {
    max-width: 100%;
    height: auto;
    display: block;
  }
`;

export const DiscountWrapper = styled.div`
  text-align: center;
`;

export const ImageSliderWrapper = styled.div`
  box-shadow: 0px 5px 10px 2px rgb(25 35 80 / 15%);
  width: 100%;
  border-radius: 8px;

  img {
    user-drag: none;
    -webkit-user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none;
  }

  > div > div > div:nth-child(2) {
    cursor: pointer;
    background-color: #fff;
    width: 4rem !important;
    height: 4rem !important;
    div:nth-child(1) {
      border-width: 0.5rem !important;
      border-color: rgba(0, 0, 0, 0) #081d34 rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) !important;
      margin-left: -1rem !important;
      margin-right: 1rem !important;
    }
    div:nth-child(2) {
      border-width: 0.5rem !important;
      border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #081d34 !important;
      margin-right: -1rem !important;
    }
  }
`;
