import styled from 'styled-components';

export const Link = styled.a`
  color: #000000;
  padding: 0;
  display: flex;
  border-radius: 30px;
  text-decoration: none;
  text-align: center;
  transition: all 200ms;
  cursor: pointer;
  margin-top: 8px;

  &:hover {
    text-decoration: underline;

    .linkArrow {
      transform: translateX(4px);
    }
  }

  .linkArrow {
    margin-left: 8px;
  }
`;

export const Text = styled.span`
  font-size: 15px;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  font-weight: bold;
  line-height: 1.4;
`;
