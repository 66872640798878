import React from 'react';
import Heading from 'reusecore/src/elements/Heading';
import { StaticImage } from 'gatsby-plugin-image';
import Container from 'common/src/components/UI/Container';
import SectionWrapper, { ContentWrapper } from './dataDrivenDesign.style';
import PropTypes from 'prop-types';
import { useIntl } from 'gatsby-plugin-react-intl';

const DataDrivenDesign = ({ header }) => {
  const intl = useIntl();

  return (
    <SectionWrapper id="data_driven_design_score_section">
      <Container>
        <ContentWrapper>
          <Heading
            content={intl.formatMessage({ id: 'landing.title4' })}
            {...header}
          />
          <div className="image">
            <StaticImage
              src="../../../images/data_driven_design.svg"
              alt="Data Driven Design Process"
            />
          </div>
        </ContentWrapper>
      </Container>
    </SectionWrapper>
  );
};

DataDrivenDesign.propTypes = {
  descriptionHeader: PropTypes.object,
  header: PropTypes.object,
  text: PropTypes.object,
};

DataDrivenDesign.defaultProps = {
  descriptionHeader: {
    textTransform: 'uppercase !important',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: ['15px !important'],
    fontWeight: 'bold !important',
    color: '#081d34 !important',
    letterSpacing: 'normal !important',
  },
  header: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    textAlign: 'center',
    style: {
      marginBottom: '2.5rem',
    },
  },
  text: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
    marginBottom: '15px',
  },
  footNote: {
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: '#081d34 !important',
    fontWeight: 300,
    fontSize: '10px !important',
    marginBottom: '15px',
  },
};

export default DataDrivenDesign;
