import React from 'react';
import Image from 'reusecore/src/elements/Image';
import { ImageWrapper } from './plugins.style';

import ChromeLogo from '../../../images/plugins/Google_Chrome_logo.svg';
import FigmaLogo from '../../../images/plugins/Figma_logo.svg';
import AdobeXDLogo from '../../../images/plugins/Adobe_XD_logo.svg';

const availablePlugins = [
  {
    id: 'figma',
    logo: FigmaLogo,
    alt: 'Figma',
    link: 'https://www.figma.com/community/plugin/1151544577194883426',
  },
  {
    id: 'adobeXD',
    logo: AdobeXDLogo,
    alt: 'Adobe XD',
    link: 'https://xd.adobelanding.com/en/xd-plugin-download/?name=4ed9388c',
  },
  {
    id: 'chrome',
    logo: ChromeLogo,
    alt: 'Google Chrome',
    link:
      'https://chrome.google.com/webstore/detail/eppmcbmbefpgbfpealidcijkjhogdooc',
  },
];

const Plugins = props => {
  return (
    <ImageWrapper {...props}>
      {availablePlugins.map((plugin, i) => {
        return (
          <div key={i}>
            <a href={plugin.link} target="_blank" rel="noreferrer">
              <Image
                className="client-logo-large"
                key={`client-key${plugin.id}`}
                src={plugin.logo}
                alt={plugin.alt}
                style={{ height: '1.6rem' }}
              />
            </a>
          </div>
        );
      })}
    </ImageWrapper>
  );
};

export default Plugins;
